import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function CustomDropDown({ value, setValue, label, list, loaderFn }) {
  const handleChange = (event) => {
    setValue(event.target.value);
    loaderFn()
  };
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}

          onChange={handleChange}>
          {list?.map(x => <MenuItem value={x.value}>{x?.label}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}