import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    show: false
}

const loaderSlice = createSlice({
    name: "loader",
    initialState,
    reducers: {
        setLoader: (state, action) => {
            state.show = action.payload
        }
    }
})

export const { setLoader } = loaderSlice.actions

export default loaderSlice.reducer