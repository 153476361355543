import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { Card, Table, Stack, Paper, Button, Popover, TableRow, MenuItem, TableBody, TableCell, Typography, TableContainer, TablePagination, CircularProgress } from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
// mock
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import FullScreenDialog from 'src/components/fullDailog';
import CustomDropDown from 'src/components/dropdown';
import { getActivity, sortActivityData } from 'src/redux/activity';
import { setLoader } from 'src/redux/loader';
// ----------------------------------------------------------------------

const TABLE_HEAD_SHIPPER = [
    { id: 'source', label: 'Source', alignRight: false },
    { id: 'destination', label: 'Destination', alignRight: false },
    { id: 'shipper', label: 'Shipper', alignRight: false },
    { id: 'date', label: "Date&Time", alignRight: false },
    { id: 'budget', label: "Budget", alignRight: false },
    { id: 'action', label: "Action", alignRight: true },
];

const TABLE_HEAD_TRANSPORTER = [
    { id: 'source', label: 'Source', alignRight: false },
    { id: 'destination', label: 'Destination', alignRight: false },
    { id: 'transporter', label: 'Transporter', alignRight: false },
    { id: 'date', label: "Date&Time", alignRight: false },
    { id: 'price', label: "Price", alignRight: false },
    { id: 'action', label: "Action", alignRight: true },
];

const activeFilterList = [
    { label: "All", value: 'all' },
    { label: "Active", value: 'active' },
    { label: "Expired", value: 'expired' }
]

const typeFilterList = [
    { label: "Shipper", value: 'shipper' },
    { label: "Transporter", value: 'transporter' }
]

const getComparator = (order, orderBy) => {
    return (a, b) => {
        if (orderBy === 'source') {
            const aValue = a.from?.address || '';
            const bValue = b.from?.address || '';
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        if (orderBy === 'destination') {
            const aValue = a.to?.address || '';
            const bValue = b.to?.address || '';
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        if (orderBy === 'shipper') {
            const aValue = (a.status === 0 && a.requestBy === "transporter") ? a.requested_to?.email || '' : a.requested_by?.email || '';
            const bValue = (b.status === 0 && b.requestBy === "transporter") ? b.requested_to?.email || '' : b.requested_by?.email || '';
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        if (orderBy === 'transporter') {
            const aValue = (a.status === 0 && a.requestBy === "transporter") ? a.requested_to?.email || '' : a.requested_by?.email || '';
            const bValue = (b.status === 0 && b.requestBy === "transporter") ? b.requested_to?.email || '' : b.requested_by?.email || '';
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        if (orderBy === 'date') {
            const aValue = moment(a.toDate, "MMM, DD, YYYY hh:mm a").toDate();
            const bValue = moment(b.toDate, "MMM, DD, YYYY hh:mm a").toDate();
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
        if (orderBy === 'Price') {
            const aValue = Number(a.subTotal) + Number(a.pickUp ? a.pickupCharges : 0) + Number(a.tax);
            const bValue = Number(b.subTotal) + Number(b.pickUp ? b.pickupCharges : 0) + Number(b.tax);
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
        if (orderBy === 'status') {
            const aValue = String(a.status || '');
            const bValue = String(b.status || '');
            return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }
        if (orderBy === 'createdAt') {
            const aValue = moment(a.createdAt).toDate();
            const bValue = moment(b.createdAt).toDate();
            return order === 'asc' ? aValue - bValue : bValue - aValue;
        }
        return 0;
    };
};

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => (_user?.to?.address?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || String(_user?.requested_to?.email).toLowerCase()?.includes(query.toLowerCase()) || String(_user?.requested_by?.email).toLowerCase().includes(query.toLowerCase())));
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function Activity() {
    const [open, setOpen] = useState(null);
    const orders = useSelector(state => state.activity)?.list
    const loader = useSelector((state) => state.loader.show)
    const sortDataRed = useSelector(state => state.activity)?.sortOrder
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState(sortDataRed?.ascDesc);
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState(sortDataRed?.orderBy);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(200);
    const [selectedStatus, setSelectedStatus] = useState(10)
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedActive, setSelectedActive] = useState('all')
    const [selectedType, setSelectedType] = useState('shipper')

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setLoader(true))
        let data = {
            activeStatus: selectedActive,
            type: selectedType
        }
        dispatch(getActivity(data))
    }, [selectedType, selectedActive])

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        dispatch(sortActivityData({
            orderBy: property,
            ascDesc: (isAsc ? 'desc' : 'asc'),
        }))
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orders?.map((n) => n?.firstName);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders?.length) : 0;

    let filteredUsers = applySortFilter(orders, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredUsers.length && !!filterName;
    filteredUsers = filteredUsers.filter(x => {
        if (selectedStatus == 10) {
            dispatch(setLoader(false))
            return true
        }
        dispatch(setLoader(false))
        return (x.status == selectedStatus)
    })

    const loaderFn = () => {
        dispatch(setLoader(true))
    }

    return (
        <>
            <Helmet>
                <title>Activity | Lugit</title>
            </Helmet>
            <div className="" style={{ paddingInline: "20px" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Activity
                    </Typography>
                </Stack>
                <Card>
                    <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 30, padding: 20 }}>
                        <CustomDropDown label={"Active Status"} list={activeFilterList} value={selectedActive} setValue={setSelectedActive} loaderFn={loaderFn} />
                        <CustomDropDown label={"Type"} list={typeFilterList} value={selectedType} setValue={setSelectedType} loaderFn={loaderFn} />
                    </div>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={selectedType === "shipper" ? TABLE_HEAD_SHIPPER : TABLE_HEAD_TRANSPORTER}
                                    rowCount={orders.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {
                                        selectedType === "shipper" &&
                                        <>
                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                const { _id } = row;
                                                const selectedUser = selected.indexOf(_id) !== -1;
                                                return (
                                                    <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                        <TableCell padding="checkbox" sx={{ paddingLeft: "15px" }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" padding="none">
                                                            {row?.pickupAddress?.address}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.destinationAddress?.address}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.user?.firstName} {row?.user?.lastName}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.deliverydateTime?.date} {row?.deliverydateTime?.time} {row?.deliverydateTime?.ampm}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            $ {row?.budget}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Button onClick={() => {
                                                                setSelectedItem(row)
                                                            }} variant='contained'>Detail</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </>
                                    }
                                    {
                                        selectedType === "transporter" &&
                                        <>
                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                const { _id, from, to, toDate } = row;
                                                const selectedUser = selected.indexOf(_id) !== -1;
                                                return (
                                                    <TableRow hover key={_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                        <TableCell padding="checkbox" sx={{ paddingLeft: "15px" }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" padding="none">
                                                            {from?.address}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {to?.address}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {row?.user?.firstName} {row?.user?.lastName}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {moment(toDate, "MMM, DD, YYYY hh:mm a").format("ddd, DD, MMM")}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            ${row?.price}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Button onClick={() => {
                                                                setSelectedItem(row)
                                                            }} variant='contained' >Detail</Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </>
                                    }
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 100, 200]}
                        component="div"
                        count={filteredUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </div>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}>
                <MenuItem>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                <MenuItem sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
            <FullScreenDialog item={selectedItem} setItem={setSelectedItem} />
        </>
    );
}