import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Stack, Avatar, Grid, TextField } from '@mui/material';
import { image_base_url } from 'src/services/endpoints';
import { setToast } from 'src/redux/toast';
import { useDispatch } from 'react-redux';
import { convertAmountInCrypto, notifyUser, UpdateOrderTrxId } from '../../redux/users';
import { useState } from 'react';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const orderTypes = {
    "0": "Requested",
    "1": "Accepted",
    "2": "Rejected",
    "3": "Picked Up",
    "4": "Delieverd",
    "5": "Received",
    "6": "Cancelled"
}
export default function FullScreenDialog({ item, setItem }) {
    const dispatch = useDispatch()
    const [priceInCrypto, setPriceInCrypto] = useState(0.00000000)
    const [recTrxId, setRecTrxId] = useState("")
    const [markAsPaid, setMarkAsPaid] = useState(false)

    const verifyTrx = (mode, trx_id) => {
        // Assuming you have different URLs based on the mode
        let url;
        if (mode === 'Ethereum' || mode === "ethereum") {
            url = `https://etherscan.io/tx/${trx_id}`;
        } else if (mode === 'Solana' || mode === "solana") {
            url = `https://explorer.solana.com/tx/${trx_id}`;
        } else {
            dispatch(setToast({ show: true, message: "Invalid Payment Mode", severity: "error" }))
        }
        // Open the URL in a new tab
        window.open(url, '_blank');
    }

    const markAsPaidToTransporter = () => {
        if (recTrxId == "") {
            dispatch(setToast({ show: true, message: "Please Mention Transaction ID", severity: "error" }))
            return false
        }
        let orderId = item?._id
        let data = {
            transporter_trx_id: recTrxId
        }
        const callback = (response) => {
            if (!response?.err) {
                item = {
                    ...item,
                    transporter_trx_id: recTrxId
                }
                setMarkAsPaid(true)
                // notify to User
                let notifData = {
                    title: "Message From Admin",
                    message: "Admin has Paid your Amount in Crypto as you submit your default Payment Method",
                    userId: item?.requested_to?._id,
                    notiType: 0
                }
                const notifCallback = () => {
                    dispatch(setToast({ show: true, message: "Transaction Marked as Paid Successfully", severity: "success" }))
                }
                dispatch(notifyUser(notifData, notifCallback))
            }
        }
        dispatch(UpdateOrderTrxId(orderId, data, callback))
    }

    const convertInCrypto = async (amount, symbol) => {
        symbol = symbol == "Ethereum" ? "ETH" : "SOL"
        let data = {
            amount: amount.toString(),
            convert: symbol
        }
        const callback = (resp) => {
            if (resp?.err) {
                // dispatch(setToast({ show: true, message: "Error While Converting Payment", severity: "error" }))
            }
            let response = parseFloat(resp?.data[symbol].price).toFixed(8)
            setPriceInCrypto(response)
        }
        dispatch(convertAmountInCrypto(data, callback))
    }

    const alertToTransporterToEnterReceiverAddress = async () => {
        let data = {
            title: "Message From Admin",
            message: "Please Submit your Crypto Receiving Address in Payment Settings to Receive Payments",
            userId: item?.requested_to?._id,
            notiType: 0
        }
        const callback = (resp) => {
            dispatch(setToast({ show: true, message: "Notify to User Success", severity: "success" }))
        }
        dispatch(notifyUser(data, callback))
    }

    useEffect(() => {
        if (item) {
            convertInCrypto(item?.subTotal + item?.pickupCharges, item?.requested_to?.payment_mode)
        }
    }, [item])

    const handleClose = () => {
        setItem(null);
    };

    return (
        <Dialog
            fullScreen
            open={Boolean(item)}
            onClose={handleClose}
            TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {
                            item?.from?.address ? <>
                                {item?.from?.address} - {item?.to?.address} ({orderTypes[`${item?.status}`]})
                            </>
                                :
                                <>
                                    {item?.destinationAddress?.address} - {item?.pickupAddress?.address}
                                </>
                        }
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box p={4}>
                <Typography variant='h5'>Destination Date & Time</Typography>
                <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                    {
                        item?.toDate ? <>
                            <Box>Name: {item?.toDate}</Box>
                        </>
                            :
                            <>
                                <Box>Name: {item?.deliverydateTime?.date} {item?.deliverydateTime?.time}{item?.deliverydateTime?.ampm}</Box>
                            </>
                    }
                </Box>
                <Grid container mt={2} spacing={2}>
                    <Grid item>
                        <Typography variant='h5'>Package Details</Typography>
                        <Stack >
                            <Box mb={2} display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                <Box>Package Type: {item?.packageSelected}</Box>
                                <Box>Description: {item?.packageDescription}</Box>
                            </Box>
                            {
                                item?.packageImages?.length > 0 &&
                                <>
                                    <Typography mb={2}>Package Images</Typography>
                                    {item?.packageImages?.map(x => (
                                        <a href={image_base_url + x} target="_blank" style={{ width: "100px" }} ><img alt="package images" src={image_base_url + x} style={{ height: "100px", width: "100px" }} /></a>
                                    ))}
                                </>
                            }
                            {
                                item?.verifiedImages?.length > 0 &&
                                <>
                                    <Typography mb={2}>PickedUp Images</Typography>
                                    {item?.verifiedImages?.map(x => (
                                        <a href={image_base_url + x} target="_blank" style={{ width: "100px" }} ><img alt="package images" src={image_base_url + x} style={{ height: "100px", width: "100px" }} /></a>
                                    ))}
                                </>
                            }
                        </Stack>
                    </Grid>
                    {
                        item?.goingBy &&
                        <Grid item ml={2}>
                            <Typography>Going By : {item?.goingBy}</Typography>
                            <Typography>Status : {orderTypes[`${item?.status}`]}</Typography>
                            <Typography>Tracking ID : #{item?.trackId}</Typography>
                            <Typography>Payment Status : {item?.paymentStatus == 0 ? "Pending" : "Completed"}</Typography>
                        </Grid>
                    }
                </Grid>
                {item?.paymentStatus != 0 &&
                    <Grid container mt={2} spacing={2}>
                        <Grid item>
                            <Stack >
                                <Typography variant='h5'>Payment Information</Typography>
                                <Box display={"flex"} flexDirection="column">
                                    {item?.payment_mode && 
                                    <Box>Payment Method: {item?.payment_mode != "" ? (item?.payment_mode[0]?.toUpperCase() + item?.payment_mode?.slice(1)) : "N/A"}</Box>}
                                    <Box>Payment Method: {item?.payment_mode}</Box>
                                    <Box>TransactionId: {item?.payment_mode == "stripe" ? item?.chargeId : item?.trx_id ?? ""}</Box>
                                    {item?.payment_mode && item?.payment_mode != "stripe" &&
                                        <Box>Verify Transaction:
                                            <Button
                                                onClick={() => verifyTrx(item?.payment_mode, item?.trx_id)}>
                                                Click here to View Trx Details
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>}
                {(item?.status == 5 || item?.status == 4) && <Grid container mt={2} spacing={2}>
                    <Grid item>
                        <Stack >
                            <Typography variant='h5'>Transporter Payment Mode</Typography>
                            <Box display={"flex"} flexDirection="column">
                                <Box>Payment Method: {item?.requested_to?.payment_method != "" ? (item?.requested_to?.payment_method[0]?.toUpperCase() + item?.requested_to?.payment_method?.slice(1)) : "N/A"}</Box>
                                {item?.requested_to?.payment_method == "Stripe" &&
                                    <Box>Payment Auto Transfered to Transporter In case of Stripe Payment Method</Box>}
                                {item?.requested_to?.payment_method == "Crypto" &&
                                    <>
                                        <Box>Payment Currency: {item?.requested_to?.payment_mode != "" ? (item?.requested_to?.payment_mode[0]?.toUpperCase() + item?.requested_to?.payment_mode?.slice(1)) : ""}</Box>
                                        <Box>{(item?.requested_to?.payment_mode[0]?.toUpperCase() + item?.requested_to?.payment_mode?.slice(1))} Receiving Address:&nbsp;
                                            {item?.requested_to?.payment_mode == "Ethereum" ? item?.requested_to?.eth_recieving_address == "" ? <Button onClick={alertToTransporterToEnterReceiverAddress}>Notify User to Add Address</Button> : item?.requested_to?.eth_recieving_address :
                                                item?.requested_to?.solana_recieving_address == "" ? <Button onClick={alertToTransporterToEnterReceiverAddress}>Notify User to Add Address</Button> : item?.requested_to?.solana_recieving_address}

                                        </Box>
                                        <Box>Amount in USD: ${item?.subTotal + item?.pickupCharges}</Box>
                                        <Box>Amount in {(item?.requested_to?.payment_mode[0]?.toUpperCase() + item?.requested_to?.payment_mode?.slice(1))}:
                                            &nbsp;{priceInCrypto}
                                        </Box>
                                        {markAsPaid || (item?.transporter_trx_id && item?.transporter_trx_id != "") ?
                                            <Box>Paid to Transporter: {item?.transporter_trx_id || recTrxId}</Box>
                                            : <Box>
                                                <div className='form-group'>
                                                    <TextField style={{ width: "500px", marginTop: "10px" }} value={recTrxId} onChange={e => setRecTrxId(e.target.value)} name="recTrxId" label="Receiver TrxId" />
                                                    <Button onClick={() => markAsPaidToTransporter()} variant='contained' style={{ height: "55px", marginTop: "10px", marginLeft: "10px" }}>Mark as Paid</Button>
                                                </div>
                                                <small>Note: This action will notify to user that your amount has been initiated by admin</small>
                                            </Box>}
                                    </>
                                }
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>}

                <Grid container mt={2} spacing={2}>
                    <Grid item>
                        <Stack >
                            <Typography variant='h5'>Shipper</Typography>
                            <Avatar sx={{ marginLeft: "10px", marginTop: "5px" }} alt={item?.requested_by?.firstName ? item?.requested_by?.firstName : item?.user?.firstName} src={image_base_url + item?.requested_by?.profilePic ? item?.requested_by?.profilePic : item?.user?.profilePic} />
                            <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                {
                                    item?.requested_by?.firstName ?
                                        <Box>Name: {item?.requested_by?.firstName == "" ? "????" : item?.requested_by?.firstName}</Box>
                                        :
                                        <Box>Name: {item?.user?.firstName == "" ? "????" : item?.user?.firstName} {item?.user?.lastName}</Box>
                                }
                                <Box>Email: {item?.requested_by?.email ? item?.requested_by?.email : item?.user?.email}</Box>
                                {
                                    item?.requested_by?.phone ?
                                        <Box>Phone: {item?.requested_by?.phone == "" ? "None" : item?.requested_by?.phone}</Box>
                                        :
                                        <Box>Phone: {item?.user?.phone}</Box>
                                }
                            </Box>
                        </Stack>
                    </Grid>
                    {
                        item?.requested_to?.firstName &&
                        <Grid item>
                            <Stack >
                                <Typography variant='h5'>Transporter</Typography>
                                <Avatar sx={{ marginLeft: "10px", marginTop: "5px" }} alt={item?.requested_to?.firstName ? item?.requested_to?.firstName : item?.user?.firstName} src={image_base_url + item?.requested_to?.profilePic ? item?.requested_to?.profilePic : item?.user?.profilePic} />
                                <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                    <Box>Name: {item?.requested_to?.firstName == "" ? "????" : item?.requested_to?.firstName}</Box>
                                    <Box>Email: {item?.requested_to?.email}</Box>
                                    <Box>Phone: {item?.requested_to?.phone == "" ? "None" : item?.requested_to?.phone}</Box>
                                </Box>
                            </Stack>
                        </Grid>
                    }
                    <Grid item>
                        <Stack >
                            <Typography variant='h5'>Receiver Details</Typography>
                            <Avatar sx={{ marginLeft: "10px", marginTop: "5px" }} alt={item?.recieverDetails?.name ?? "" } src={image_base_url??"" } />
                            <Box display={"flex"} flexDirection="column" sx={{ marginLeft: "10px" }}>
                                <Box>Name: {item?.reciever?.name ? item?.reciever?.name : item?.recieverDetails?.name}</Box>
                                <Box>Email: {item?.reciever?.email ? item?.reciever?.email : item?.recieverDetails?.email}</Box>
                                <Box>Phone: {item?.reciever?.phone ? item?.reciever?.phone : item?.user?.phone}</Box>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}